<template>
  <div class="gateway">
    <!-- 参数 -->
    <Head :name="'超级主机'" :add="'/pc/ProGateway'" :adds="'/pc/ProGatewayParam'" :color="true" />
    <!-- 占位 -->
    <!-- <div style="min-height:0.6rem;background-color:#414141"></div> -->
    <!-- banner -->
    <div class="bannerBox">
      <div class="banner">
        <div class="bName">超级主机</div>
        <div class="bTit">一个主机，改变一切</div>
        <div class="bTxt">强大的系统硬件，集不同设备和功能于一身。</div>
        <div class="bTxts">更有优秀的配套软硬件解决方案，让您拥有舒适，自然的入住体验</div>
      </div>
    </div>
    <!-- 四个优点 -->
    <div class="advantage">
      <div class="adv">
        <div class="advBox">
          <div class="advOne">覆盖广</div>
          <div class="advTwo">轻松覆盖500㎡</div>
        </div>
      </div>
      <div class="adv">
        <div class="advBox">
          <div class="advOne">稳定可靠</div>
          <div class="advTwo">自研技术，不惧干扰</div>
        </div>
      </div>
      <div class="adv">
        <div class="advBox">
          <div class="advOne">低功耗</div>
          <div class="advTwo">减少开支，每月仅需一度电</div>
        </div>
      </div>
      <div class="adv">
        <div class="advBox">
          <div class="advOne">隐私保护</div>
          <div class="advTwo">保障用户隐私安全</div>
        </div>
      </div>
    </div>
    <!-- 超级主机 -->
    <div class="superGatewayBox">
      <div class="superGateway">
        <div class="superTit">强大到不可想象的超级主机</div>
        <div class="superTxt">基于我们为此打造的强大简洁的主机系统和互联技术，使用户可以通过按键，触摸屏，</div>
        <div class="superTxts">语音和微信小程序等多种交互方式实现对屋内照明、空调、电动窗帘和其他智能设备系统的智能化集中控制。</div>
        <div class="superImgBox">
          <div class="superImg">
            <div class="imgName">灯光控制</div>
          </div>
          <div class="superImg">
            <div class="imgName">空调控制</div>
          </div>
        </div>
        <div class="superImgBox">
          <div class="superImg">
            <div class="imgName">窗帘控制</div>
          </div>
          <div class="superImg">
            <div class="imgName">音乐控制</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec1">
      <div class="seTit">前所未有的人性化智能互联体验</div>
      <div class="seTxt">基于主机内置的可扩展系统以及强大的云计算和人工智能技术为支撑，使配置和安装的效率提高的史无前例。</div>
      <div class="seTxts">同时，让用户的体验更加简单舒适。</div>
    </div>
    <div class="sec2">
      <div class="sec2Box">
        <div class="seTit">一切，出口即成</div>
        <div class="seTxt">唤醒智能助理，</div>
        <div class="seTxts">无需拿出手机即可实现对智能设备的控制。</div>
      </div>
    </div>
    <div class="sec3">
      <div class="sec3Box">
        <div class="seTit">无线，无限</div>
        <div class="seTxt">使用我们自研的高性能低延迟的无线组网方案，</div>
        <div class="seTxts">传输快，距离远，抗干扰能力强。</div>
      </div>
    </div>
    <div class="sec4">
      <div class="seTit">一切，由你来定</div>
      <div class="seTxt">基于我们自己研发的强大的主机系统，</div>
      <div class="seTxts">实现完全定制化。</div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.bannerBox {
  // padding-top: 0.6rem;
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/gateBanner.png") no-repeat center
    center;
  background-size: cover;
  .banner {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    font-size: 0.18rem;
    line-height: 0.18rem;
    font-weight: 400;
    .bName {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      margin-top: 2.32rem;
    }
    .bTit {
      margin-top: 0.23rem;
      font-size: 0.3rem;
      line-height: 0.3rem;
    }
    .bTxt {
      margin-top: 0.99rem;
    }
    .bTxts {
      margin-top: 0.1rem;
    }
  }
}
// 四个优点
.advantage {
  width: 100%;
  height: 3rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .adv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 0.6rem;
    border-right: 1px solid #333;
    text-align: left;
    box-sizing: border-box;
    &:last-of-type {
      border-right: 0px;
    }
    .advOne {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: bold;
      color: #ff9000;
    }
    .advTwo {
      font-size: 0.18rem;
      line-height: 0.18rem;
      font-weight: 400;
      color: #666666;
      margin-top: 0.12rem;
    }
  }
}
//超级主机
.superGatewayBox {
  width: 100%;
  height: 12.58rem;
  background-color: #f8f8f8;
  .superGateway {
    width: 12rem;
    margin: 0 auto;
    text-align: center;
    padding-top: 0.8rem;
    color: #333;
    font-size: 0.24rem;
    line-height: 0.24rem;
    .superTit {
      font-size: 0.36rem;
      font-weight: bold;
      line-height: 0.36rem;
    }
    .superTxt {
      margin-top: 0.39rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.24rem;
    }
    .superTxts {
      margin-top: 0.1rem;
    }
    .superImgBox {
      width: 100%;
      margin-top: 0.8rem;
      display: flex;
      justify-content: center;
      .superImg {
        width: 5.8rem;
        height: 4rem;
        margin-right: 0.4rem;
        position: relative;
        background-size: 100% 100%;
        &:first-of-type {
          background-image: url("../../../assets/product/ctrl1.png");
        }
        &:nth-child(2) {
          margin-right: 0px;
          margin-bottom: 0.4rem;
          background-image: url("../../../assets/product/ctrl2.png");
        }

        .imgName {
          position: absolute;
          left: 0.29rem;
          bottom: 0.27rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: bold;
          color: #ffffff;
        }
      }
      &:last-of-type {
        margin-top: 0px;
        .superImg {
          &:nth-child(1) {
            margin-bottom: 0.4rem;
            background-image: url("../../../assets/product/ctrl3.png");
          }
          &:last-of-type {
            margin-bottom: 0.4rem;
            background-image: url("../../../assets/product/ctrl4.png");
          }
        }
      }
    }
  }
}
.sec1 {
  width: 100%;
  height: 3.38rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #333;
  font-size: 0.24rem;
  line-height: 0.24rem;
  .seTit {
    margin-top: 1.2rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
  }
  .seTxt {
    margin-top: 0.39rem;
  }
  .seTxts {
    margin-top: 0.1rem;
  }
}
.sec2 {
  width: 100%;
  height: 6rem;
  background-image: url("../../../assets/product/sec1.png");
  background-size: 100% 100%;
  .sec2Box {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #333;
    .seTit {
      margin-top: 2.32rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 800;
    }
    .seTxt {
      margin-top: 0.39rem;
    }
    .seTxts {
      margin-top: 0.1rem;
    }
  }
}
.sec3 {
  width: 100%;
  height: 6rem;
  background-image: url("../../../assets/product/sec2.png");
  background-size: 100% 100%;
  .sec3Box {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #fff;
    .seTit {
      margin-top: 2.32rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 800;
    }
    .seTxt {
      margin-top: 0.37rem;
    }
    .seTxts {
      margin-top: 0.16rem;
    }
  }
}
.sec4 {
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  background-image: url("../../../assets/product/sec3.png");
  background-size: 100% 100%;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
  color: #fff;
  .seTit {
    margin-top: 2.3rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: 800;
  }
  .seTxt {
    margin-top: 0.37rem;
  }
  .seTxts {
    margin-top: 0.16rem;
  }
}
</style>
